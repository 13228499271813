<template>
	<div class="vertical-align-parent">
		<div class="vertical-align-child">
			<div class="columns is-vcentered">
				<div class="column is-3">
					<button v-for="item in rightData" class="button is-white is-fullwidth is-medium is-outlined mb30" data-aos="zoom-in-up"
					data-aos-easing="linear"
					data-aos-duration="700"
					@mouseover="showDesc(item)" 
					@mouseleave="hideDec()">{{item.title}}</button>	 
				</div>
				<div class="column is-6">
					<div :class="[hovered ==true ? 'show-desc' : '']" class="desc has-text-white has-text-centered pr20 pl20">
						{{desc}}
					</div>
					<figure class="image height-450 image-contain mt20" :class="[hovered ==true ? '' : '']">
						<lazy-load
						src="/front/img/spinner.gif"
						lazy-src="/front/img/illustration/server.svg"
						lazy-srcset="/front/img/illustration/server.svg"
						background-color="transparent"
						alt="Project Name"
						/>
					</figure>
				</div>
				<div class="column is-3">
					<button v-for="item in leftData" class="button is-white is-fullwidth is-medium is-outlined mb30" data-aos="zoom-in-up"
					data-aos-easing="linear"
					data-aos-duration="700" 
					@mouseover="showDesc(item)" 
					@mouseleave="hideDec()">{{item.title}}</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Features',
		props: {
			rightColumn:{
				required:true,
				type:Array
			},
			leftColumn:{
				required:true,
				type:Array
			}
		},
		computed: {
			
		},
		mounted() {
			
		},
		data() {
			return {
				rightData:[],
				leftData:[],
				hovered:false,
				desc:null
			};
		},
		methods: {
			showDesc(item){
				this.hovered = true;
				this.desc = item.desc;
			},
			hideDec(){
				this.hovered = false;
				this.desc = null;
			},
			addDelay(right , left){
				right.forEach(function(element , index) {
					if(index==0 || index==1){
						element.delay=0
					}
					if(index==2 || index==3){
						element.delay=100
					}
				});
				left.forEach(function(element , index) {
					if(index==0 || index==1){
						element.delay=200
					}
					else if(index==2 || index==3){
						element.delay=300
					}
				})
			},
		},
		created() {
			this.rightData = this.rightColumn;
			this.leftData = this.leftColumn;
			this.addDelay(this.rightData , this.leftData);
		}
	};
</script>

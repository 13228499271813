<template>
	<div class="apply">
		<a href="#" class="button is-primary is-medium is-radiusless hvr-border-top-bottom pr40 pl40" v-if="JobId == ''" @click.prevent="handelModal">كُن أحد نجومنا</a>
		<a href="#" class="link is-size-5" v-else @click.prevent="handelModal">
			<span class="has-text-weight-bold text">قدم علي الوظيفة </span>
			<i class="fas fa-chevron-left icon"></i>
		</a>
		<div class="modal modal-fx-slideTop apply-modal" :class="[Opened ? 'is-active' :'']">
			<div class="modal-background"></div>
			<div class="modal-content p20">
				<div class="modal-inner has-boxshadow-modal has-background-white pt50 pb50 has-text-centered">
					<h3 class="has-text-primary is-size-5">كن فردا منا </h3>
					<h3 class="has-text-primary is-size-4 has-text-weight-bold mb40">قدم علي الوظيفة </h3>
					<div class="columns is-centered">
						<div class="column is-9">
							<div class="field">
								<div class="control has-icons-left has-icons-right">
									<input name="name" class="input is-medium has-text-centered"
									:class="[errors.has('name') ? 'is-danger' : 'is-info']" type="text"
									:placeholder="t('name')"
									v-model="form.name"
									v-validate="{ required: true , min:3 }"
									required >
									<div v-if="errors.has('name')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('name') }}</div>
									<span v-if="errors.has('name')" class="icon is-small is-left has-text-danger">
										<i class="fas fa-exclamation-circle"></i>
									</span>
								</div>
							</div>
							<div class="field">
								<div class="control has-icons-left has-icons-right">
									<input name="email" class="input is-medium has-text-centered"
									:class="[errors.has('email') ? 'is-danger' : 'is-info']" type="text"
									:placeholder="t('email')"
									v-model="form.email"
									v-validate="{ required: true , email:true }"
									required >
									<div v-if="errors.has('email')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('email') }}</div>
									<span v-if="errors.has('email')" class="icon is-small is-left has-text-danger">
										<i class="fas fa-exclamation-circle"></i>
									</span>
								</div>
							</div>
							<div class="field">
								<div class="control has-icons-left has-icons-right">
									<input name="phone" class="input is-medium has-text-centered"
									:class="[errors.has('phone') ? 'is-danger' : 'is-info']" type="text"
									:placeholder="t('phone')"
									v-model="form.phone"
									v-validate="{ required: true , length:11 , numeric:true }"
									required >
									<div v-if="errors.has('phone')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('phone') }}</div>
									<span v-if="errors.has('phone')" class="icon is-small is-left has-text-danger">
										<i class="fas fa-exclamation-circle"></i>
									</span>
								</div>
							</div>
							<div class="field">
								<div class="control has-icons-left has-icons-right">
									<div class="cv-uploader" :class="[errors.has('cv') ? 'is-danger' : 'is-info']">
										<input type="file" name="cv" v-validate="{ required: true , ext:['pdf','doc','docx'] }" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
										<label class="has-text-centered">
											<i class="uploader-icon icon-cv"></i>
											<span class="uploader-text has-text-primary has-text-weight-bold">ارفع السيرة الذاتية </span>
										</label>
									</div>
									<div v-if="errors.has('cv')" class="has-text-danger has-text-centered p5 has-background-alpha-red position-relative">{{ errors.first('cv') }}</div>
									<span v-if="errors.has('cv')" class="icon is-small is-left has-text-danger">
										<i class="fas fa-exclamation-circle"></i>
									</span>
								</div>
							</div>
							<input v-if="JobId != ''" type="hidden" name="JobId" :value="JobId">
							<div class="mt-20 has-text-centered">
								<button type="submit" class="button is-primary is-medium is-radiusless pr30 pl30" @click.prevent="submit">
									<span>
										{{t('send_cv')}}
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button v-if="Opened" class="modal-close is-large" aria-label="close" @click.prevent="handelModal"></button>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import trans from "./translation/general.json";
	import arabic from 'vee-validate/dist/locale/ar';
	import { Validator } from 'vee-validate';
	export default {
		name: 'ApplyJob',
		props: {
			sitelocale:{
				type:String,
				required: true
			},
			JobId:{
				type:String,
				required:true
			},
			action:{
				type:String,
				required:true
			}
		},
		data() {
			return {
				Opened:false,
				form:{
					name:null,
					email:null,
					phone:null,
					cv:null,
					company_job_id:this.JobId ? this.JobId : null
				}
			};
		},
		locales: trans,
		computed: {

		},
		mounted() {
			this.$translate.setLang(this.sitelocale);
		},
		created() {
			this.$validator.localize('ar', {
				messages: arabic.messages,
				attributes: trans.ar,
				custom:trans.ar.custom
			});
			this.$validator.localize('en', {
				custom:trans.en.custom
			});

			Validator.localize(this.sitelocale);
		},
		methods: {
			handelModal(){
				this.Opened= !this.Opened;
				if(this.Opened){
					$('html').addClass('modal-openend')
				}
				else{

					setTimeout(function(){
						$('html').removeClass('modal-openend')
					}, 300);
				}
			},
			submit(){
				this.$validator.validateAll().then((result) => {
					if (result) {
						const action = this.action;
						axios.post(action, this.form)
						.then(response => {
							this.showAlert('success' , response.data.message  , '' , '');
							this.form={
								name:null,
								email:null,
								phone:null,
								cv:null,
							}

						})
						.catch(error => {
							this.showAlert('error',
								`${this.$translate.text('يرجي مراجعة البيانات')}`,
								'text',
								`<ul class="AlertList">`+Object.values(error.response.data.errors).map(error => `<li class="mb5 is-size-6">${error[0]}</li>`).join(' ')+`</ul>`
								)
							console.clear();
						})
						.then(()=>{

							setTimeout(()=>{

							}, 3500);
						});

					}

				});
			},
			showAlert(type , title , text , html){
				this.$swal({
					type: type,
					title: title,
					text: text,
					html: html,
					showConfirmButton: false,
					timer:2000,
					customClass: type == 'success' ? 'swal2-is-success' : 'swal2-is-danger',
					backdrop: `
					rgba(36, 140, 204, 0.5)
					`

				});
			}
		},

	};
</script>

<template>
	<div class="gallery-filter">
		<div class="container" v-if="categories != ''">
			<div class="buttons-container has-text-centered">
				<a href="#" class="button has-text-black-ter has-text-weight-bold"  @click.prevent="filterall()" :class="[selectedCat==null ? 'is-checked' : '']"><span>{{t('all')}}</span></a>
				<a href="#" class="button has-text-black-ter has-text-weight-bold " v-for="cat in categories" :class="[cat.slug==selectedCat ? 'is-checked' : '']" @click.prevent="filtercat(cat)"><span>{{ sitelocale == 'ar' ? cat.name_ar : cat.name_en}}</span></a>
			</div>
		</div>
		<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="8">
			<div class="container projects">
				<div class="columns is-multiline" v-if="display_list.length > 0">
					<div class="column is-3" v-for="item in display_list">
						<div class="card-grey has-background-white radius-xs animated " :class="[zoomIn ? 'fadeIn' : '']" v-show="zoomIn">
							<a :href="item.link">
								<figure class="image image-cover">
									<lazy-load
									src="/front/img/spinner.gif"
									:lazy-src="item.image ? item.image : '/front/img/projects/project1.png'"
									:lazy-srcset="item.image ? item.image : '/front/img/projects/project1.png'"
									background-color="transparent"
									alt="Project Name"
									/>
								</figure>
								<div class="info has-background-alpha-white">
									<h3 class="title is-size-5 has-text-weight-bold has-text-white p15 has-background-primary">{{item.name}}</h3>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div v-if="display_list.length == 0" class="empty-page">
					<figure class="image-contain height-400 full-width">
						<lazy-load
						src="/front/img/spinner.gif"
						lazy-src="/front/img/illustration/empty.svg"
						lazy-srcset="/front/img/illustration/empty.svg"
						background-color="transparent"
						/>
					</figure>
					<p v-if="isEmpty" class=" has-text-primary is-size-5 has-text-weight-bold has-text-centered">{{t('no_data')}}</p>
				</div>
			</div>
		</div>
		<div class="has-text-centered mt70" v-show="loadMore">
			<figure class="image image-contain is-64x64 mr-auto">
				<img src="/front/img/loading_state.svg">
			</figure>
		</div>
	</div>
</template>

<script>

	import trans from "./translation/gallery.json";
	import infiniteScroll from 'vue-infinite-scroll';
	export default {
		name: 'InnerGallery',
		directives: {
			infiniteScroll
		},
		props: {
			sitelocale: {
				type: String,
				required: true
			},
			categories:{
				required: true
			},
			url : {
				required : true
			}

		},
		data() {
			return {
				options:{
					slidesToShow:5,
					slidesToScroll: 1 ,
					arrows: false,
					dots: false,
					draggable: true,
					focusOnSelect: true ,
					centerMode: true,
					swipeToSlide:true
				},
				display_list:null,
				selectedCat: null,
				zoomIn:true,
				items: [],
				order : [],
				currentPage: 0,
				fetching: false,
				nextUrl : String,
				loadMore : true,
				isActive: false,
				list : [],
				isEmpty:false
			}
		},
		computed: {
			busy() {
				return this.fetching
			}
		},
		mounted() {
			this.$translate.setLang(this.sitelocale);

		},
		created(){
			this.display_list = [];
			this.nextUrl = this.url;
			this.items = [];
			this.loadNextPage();
		},
		locales: trans,
		methods: {
			filtercat(cat){
				this.zoomIn = false;
				this.selectedCat = cat.slug;
				this.list = [];
				this.display_list = [];
				this.nextUrl = cat.url;
				this.loadNextPage();
				var self = this;
				setTimeout(function(){
					self.zoomIn = true;
				}, 50);


			},
			filterall(){
				this.zoomIn = false;
				this.selectedCat = null;
				this.list = [];
				this.display_list = [];
				this.nextUrl = this.url;
				this.loadNextPage();
				var self = this;
				setTimeout(function(){
					self.zoomIn = true;
				}, 50);
			},
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					axios.get(this.nextUrl).then(({ data }) => {
						this.list.push(...data.data);
						this.display_list.push(...data.data);
						if(data.links.next != null) {
							this.loadMore = true;
							this.nextUrl = data.links.next;
						} else {
							this.nextUrl = data.links.next;
							this.loadMore = false;
						}
						if(this.display_list.length == 0){
							this.isEmpty = true;
						}
						else{
							this.isEmpty = false;
						}
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			}
		},
	};
</script>

<style lang="scss">

</style>



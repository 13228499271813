<template>
    <div>
        <div class="share mt30" :class="[opened == true ? 'open' : '']">

            <a href="#" class="share-button" @click.prevent="handleMenu">
                <i class="icon-share icon"></i>
            </a>
            <social-sharing :url="PostDetails.url"
            :title="PostDetails.title"
            :description="PostDetails.description"
            :quote="PostDetails.title"
            hashtags="Murabba "
            twitter-user="Murabba"
            inline-template>
            <div class="share-list">
                <network class="social-sharing hvr-grow" network="email">
                    <i class="far fa-envelope"></i>
                </network>
                <network class="social-sharing hvr-grow" network="facebook">
                    <i class="fab fa-facebook-f"></i>
                </network>
                <network class="social-sharing hvr-grow" network="linkedin">
                    <i class="fab fa-linkedin-in"></i>
                </network>
                <network class="social-sharing hvr-grow" network="sms">
                    <i class="fas fa-sms"></i>
                </network>
                <network class="social-sharing hvr-grow" network="twitter">
                    <i class="fab fa-twitter"></i>
                </network>
                <network class="social-sharing hvr-grow" network="whatsapp">
                    <i class="fab fa-whatsapp"></i>
                </network>
            </div>
        </social-sharing>
    </div>

</div>
</template>

<script>
    export default {
        name: 'SharePost',
        props: {
            sitelocale: {
                type: String,
                required: true
            },
            PostDetails:{
                type: Object,
                required: true
            },
        },
        computed: {

        },
        mounted() {
        },
        data: () => ({
            opened:false
        }),
        methods: {
            handleMenu(){
                this.opened = !this.opened
            }
        },
        
        
    };
</script>

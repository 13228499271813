<template>
    <div class="flash-offers has-background-accent" v-if="!closed">
        <div class="container">
            <div class="columns is-vcentered">
                <div class="column is-4">
                    <figure class="image-contain full-width height-130">
                        <img :src="sale.image">
                    </figure>
                </div>
                <div class="column is-4">
                    <div class="has-text-centered">
                        <h3 class="has-text-weight-bold has-text-white is-size-4">{{ sale.name }}</h3>
                        <div class="stop-watch-container mt5">
                            <countdown :time="time">
                                <template slot-scope="props">
                                    <div class="stop-watch-block is-inline-block has-background-pink has-text-white">
                                        <p class="is-size-4 has-text-weight-bold">{{ props.days }}</p>
                                        <p class="is-size-7">{{ t('days')}}</p>
                                    </div>
                                    <div class="stop-watch-block is-inline-block has-background-pink has-text-white">
                                        <p class="is-size-4 has-text-weight-bold">{{ props.hours }}</p>
                                        <p class="is-size-7">{{ t('hours')}}</p>
                                    </div>
                                    <div class="stop-watch-block is-inline-block has-background-pink has-text-white">
                                        <p class="is-size-4 has-text-weight-bold">{{ props.minutes }}</p>
                                        <p class="is-size-7">{{ t('minutes')}}</p>
                                    </div>
                                    <div class="stop-watch-block is-inline-block has-background-pink has-text-white">
                                        <p class="is-size-4 has-text-weight-bold">{{ props.seconds }}</p>
                                        <p class="is-size-7">{{ t('seconds')}}</p>
                                    </div>
                                </template>
                            </countdown>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="has-text-centered">
                        <h3 class="has-text-weight-bold has-text-white is-size-5 mb10">
                            <span class="is-block">{{ t('join_offer')}}</span>
                            <span class="is-block">{{ t('limited_time')}}</span>
                        </h3>
                        <a :href="sale.link" class="button is-pink  has-text-white pt20 pb20 has-text-weight-bold is-radiusless hvr-border-top-bottom">{{ t('get_offer')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="close-conatiner">
            <button class="modal-close is-large" aria-label="close" @click="closeOffer()"></button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import trans from "./translation/general.json";
    export default {
        name: 'FlashSale',
        props: {
            sitelocale: {
                type: String,
                required: true
            },
            endDate:{
               required: true
           },
            sale:{
                required : true
            },
            action: {
                type: String,
                required: true
            },
       },
       data() {
        return {
            now:null,
            countDownDate:null,
            time:null,
            closed:false
        }
    },
    mounted() {
        this.$translate.setLang(this.sitelocale);
        this.countDownDate = new Date(this.endDate).getTime();
        this.now = new Date().getTime();
        this.time = this.countDownDate - this.now
    },
    created(){
        var closed = this.closed;
        $(window).scroll(function() {
            if(!closed){
                if ($(this).scrollTop() > 50) {
                    $('.flash-offer-exist').removeClass('flash-offer-exist');
                }
                else if($(this).scrollTop() <= 50){
                   $('.hero.is-white').addClass('flash-offer-exist');
               }
           }
       });

    },
    locales: trans,
    methods: {
        closeOffer(){
            this.closed = true;
            $('.flash-offer-exist').removeClass('flash-offer-exist');
        }

    },
    watch: {
        closed: function () {
            var closed = this.closed;
            axios.get(this.action);
            $(window).scroll(function() {
                if(closed){
                    if ($(this).scrollTop() > 50) {
                        $('.flash-offer-exist').removeClass('flash-offer-exist');
                    }
                    else if($(this).scrollTop() <= 50){
                       $('.hero.is-white').removeClass('flash-offer-exist');
                   }
               }
           });
        },
    }
};
</script>

<style lang="scss">

</style>



<template>
	<div class="hero-head headroom header" :class="{'headroom--unpinned': scrolled , 'header-scrolled' : scrolledTiny}"  v-on:scroll="handleScroll" >
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'sticky-header',
	data() {
		return {
			limitPosition: 500,
			scrolled: false,
			scrolledTiny:false,
			lastPosition: 0
		};
	},

	methods: {
		handleScroll() {
			if(window.scrollY > 0){
				this.scrolledTiny=true
			}
			else{
				this.scrolledTiny=false
			}
			if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
				this.scrolled = true;
        // move up!
    } 

    if (this.lastPosition > window.scrollY) {
    	this.scrolled = false;
        // move down
    }

    this.lastPosition = window.scrollY;
      // this.scrolled = window.scrollY > 250;
  }
},
created() {
	window.addEventListener("scroll", this.handleScroll, {passive: true});
},
destroyed() {
	window.removeEventListener("scroll", this.handleScroll, {passive: true});
}
};
</script>
<style lang="scss">

</style>
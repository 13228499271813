<template>
	<div class="contact-form">
		<div class="field">
			<div class="control has-icons-left has-icons-right">
				<input name="name" class="input is-medium has-text-centered"
				:class="[errors.has('name') ? 'is-danger' : 'is-info']" type="text"
				:placeholder="t('name')"
				v-model="form.name"
				v-validate="{ required: true , min:3 }"
				required >
				<div v-if="errors.has('name')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('name') }}</div>
				<span v-if="errors.has('name')" class="icon is-small is-left has-text-danger">
					<i class="fas fa-exclamation-circle"></i>
				</span>
			</div>
		</div>
		<div class="field">
			<div class="control has-icons-left has-icons-right">
				<input name="email" class="input is-medium has-text-centered"
				:class="[errors.has('email') ? 'is-danger' : 'is-info']" type="text"
				:placeholder="t('email')"
				v-model="form.email"
				v-validate="{ required: true , email:true }"
				required >
				<div v-if="errors.has('email')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('email') }}</div>
				<span v-if="errors.has('email')" class="icon is-small is-left has-text-danger">
					<i class="fas fa-exclamation-circle"></i>
				</span>
			</div>
		</div>
		<div class="field">
			<div class="control has-icons-left has-icons-right">
				<input name="title" class="input is-medium has-text-centered"
				:class="[errors.has('title') ? 'is-danger' : 'is-info']" type="text"
				:placeholder="t('title')"
				v-model="form.title"
				v-validate="{ required: true , min:3 }"
				required >
				<div v-if="errors.has('title')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('title') }}</div>
				<span v-if="errors.has('title')" class="icon is-small is-left has-text-danger">
					<i class="fas fa-exclamation-circle"></i>
				</span>
			</div>
		</div>
		<div class="field">
			<div class="control has-icons-left has-icons-right">
				<textarea name="message" class="textarea is-medium has-text-centered"
				:class="[errors.has('message') ? 'is-danger' : 'is-info']" type="text"
				:placeholder="t('message')"
				v-model="form.message"
				v-validate="{ required: true , min:3 }"
				required></textarea>
				<div v-if="errors.has('message')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('message') }}</div>
				<span v-if="errors.has('message')" class="icon is-small is-left has-text-danger">
					<i class="fas fa-exclamation-circle"></i>
				</span>
			</div>
		</div>
		<div class="mt-20 has-text-centered">
			<button type="submit" class="button is-primary is-radiusless has-text-weight-bold" @click.prevent="submit">
				<span class="icon">
					<i class="icon-plane"></i>
				</span>
				<span>
					{{t('send')}}
				</span>
			</button>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import trans from "./translation/general.json";
	import arabic from 'vee-validate/dist/locale/ar';
	import { Validator } from 'vee-validate';
	export default {
		name: 'ContactUs',
		props: {
			sitelocale:{
				type:String,
				required: true
			},
			action:{
				type:String,
				required: true
			}
		},
		locales: trans,
		computed: {

		},
		mounted() {
			this.$translate.setLang(this.sitelocale);
		},
		created () {
			this.$validator.localize('ar', {
				messages: arabic.messages,
				attributes: trans.ar,
				custom:trans.ar.custom
			});
			this.$validator.localize('en', {
				custom:trans.en.custom
			});

			Validator.localize(this.sitelocale);
		},
		data: () => ({
			opened:false,
			opening:false,
			elements:[],
			form:{
				name:null,
				email:null,
				phone:null,
				title:null,
				message:null,
			}
		}),
		methods: {
			submit(){
				this.$validator.validateAll().then((result) => {
					if (result) {
						const action = this.action;
						axios.post(action, this.form)
						.then(response => {
							this.showAlert('success' , response.data.message  , '' , '');
							this.form={
								name:null,
								email:null,
								phone:null,
								title:null,
								message:null,
							}

						})
						.catch(error => {
							this.showAlert('error',
								`${this.$translate.text('يرجي مراجعة البيانات')}`,
								'text',
								`<ul class="AlertList">`+Object.values(error.response.data.errors).map(error => `<li class="mb5 is-size-6">${error[0]}</li>`).join(' ')+`</ul>`
						)
							console.clear();
						})
						.then(()=>{

							setTimeout(()=>{

							}, 3500);
						});

					}

				});
			},
			showAlert(type , title , text , html){
				this.$swal({
					type: type,
					title: title,
					text: text,
					html: html,
					showConfirmButton: false,
					timer:2000,
					customClass: type == 'success' ? 'swal2-is-success' : 'swal2-is-danger',
					backdrop: `
					rgba(36, 140, 204, 0.5)
					`

				});
			}
		},
	};
</script>


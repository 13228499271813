'use strict';

document.addEventListener('DOMContentLoaded', function () {

  if(window.innerWidth <= 1023){
    // Dropdowns
    var $dropdowns = getAll('.dropdown.is-clickable-mobile');
    if ($dropdowns.length > 0) {
      $dropdowns.forEach(function ($el) {
        $el.classList.remove("is-hoverable");
        $el.addEventListener('click', function (event) {
          event.stopPropagation();
          $el.classList.toggle('is-active');
        });
      });

      document.addEventListener('click', function (event) {
        closeDropdowns();
      },{passive: true});
    }

    function closeDropdowns() {
      $dropdowns.forEach(function ($el) {
        $el.classList.remove('is-active');
      });
    }

    // Close dropdowns if ESC pressed
    document.addEventListener('keydown', function (event) {
      var e = event || window.event;
      if (e.keyCode === 27) {
        closeDropdowns();
      }
    }, {passive: true});

    // Functions

    function getAll(selector) {
      return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
    }
  }

  setTimeout(()=>{
    $('.loader-container').fadeOut(1000);    
    setTimeout(()=>{
      $('html').removeClass('modal-openend');
      $('body').addClass('animated');
    },500)
  }, 1000)
  
  window.addEventListener("scroll", function() {

    var elementTarget = document.getElementById("galleryPage");
    var elementFoot = document.getElementById("galleryFoot");
    var mainFooter = document.getElementById("MainFooter");
    if(elementFoot){
      if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight - mainFooter.offsetHeight)) {
        // alert("You've scrolled past the second div");
        elementFoot.classList.remove('is-fixed');
      }
      else{
        elementFoot.classList.add('is-fixed');
      }
    }
  },{passive: true});

},{passive: true});




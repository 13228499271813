<template>
	<h3>
		<p v-for="item in output">{{item}}</p>
	</h3>
</template>
<script>
	export default {
		name: 'SplitHeader',
		props: {
			text:{
				type:String
			}
		},
		computed: {
			
		},
		mounted() {
			this.data = this.text;
			let da = this;
			this.data.split(" ").map(function(item) {
				da.output.push(item);
			});
		},
		data() {
			return {
				data:null,
				output:[]
			};
		},
		methods: {
			
		},
		created() {
			
		}
	};
</script>
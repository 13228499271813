<template>
	<div :class="[offers==false ? 'blog-container' : 'offers-container']">
		<div v-if="items">
			<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="8">
				<div class="columns is-multiline is-centered">
					<div v-if="offers == false" class="column is-3" v-for="post in items">
						<div class="card-grey overflow-hidden has-background-white blog-card">
							<a :href="post.link">
								<figure class="full-width height-150 image-cover">
									<lazy-load
									src="/front/img/spinner.gif"
									:lazy-src="post.image ? post.image : '/front/img/slider/blog.png'"
									:lazy-srcset="post.image ? post.image : '/front/img/slider/blog.png'"
									background-color="transparent"
									class="item-image"
									:alt="post.title"
									/>
								</figure>
								<div class="p10 has-text-centered">
									<h3 class="has-text-primary has-text-weight-bold is-size-5 overflow-hidden name mb20">{{post.title}}</h3>
									<div class="desc has-text-black-bis is-size-5 mb30 overflow-hidden" v-html="post.desc">

									</div>
								</div>
							</a>
						</div>
					</div>
					<div v-if="offers == true"  class="column is-6" v-for="post in items">
						<div class="card-grey radius-xs p20">
							<div class="columns is-vcentered is-mobile">
								<div class="column is-4">
									<figure class="image image-contain height-200 full-width">
										<lazy-load
										src="/front/img/spinner.gif"
										:lazy-src="post.image ? post.image : '/front/img/slider/offer.svg'"
										:lazy-srcset="post.image ? post.image : '/front/img/slider/offer.svg'"
										background-color="transparent"
										class="item-image"
										:alt="post.title"
										/>
									</figure>
								</div>
								<div class="column is-8">
									<h3 class="has-text-weight-bold is-size-6 overflow-hidden name mb20">{{post.title}}</h3>
									<div class="desc is-size-6 mb30 overflow-hidden" v-html="post.desc"></div>
									<a :href="post.link" class="button is-radiusless">اقرا المزيد</a>
									<span v-if="!post.available" class="not-available">غير متاح</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="has-text-centered mt70" v-show="loadMore">
				<figure class="image image-contain is-64x64 mr-auto">
					<img src="/front/img/loading_state.svg">
				</figure>
			</div>
		</div>
		<div v-else class="empty-page">
			<figure class="image-contain height-400 full-width">
				<lazy-load
				src="/front/img/spinner.gif"
				lazy-src="/front/img/illustration/404.svg"
				lazy-srcset="/front/img/illustration/404.svg"
				background-color="transparent"
				/>
			</figure>
			<p class=" has-text-primary is-size-5 has-text-weight-bold has-text-centered">{{t('no_data')}}</p>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import infiniteScroll from 'vue-infinite-scroll';
	import trans from "./translation/general.json";
	export default {
		name: 'Blog',
		directives: {
			infiniteScroll
		},
		props: {
			url: String,
			sitelocale:{
				type:String,
				required: true
			},
			offers:{
				type:Boolean,
				required: true
			}
		},
		locales: trans,
		computed: {
			busy() {
				return this.fetching
			}
		},
		mounted() {
			this.$translate.setLang(this.sitelocale);
		},
		data: () => ({
			items: [],
			order : [],
			currentPage: 0,
			fetching: false,
			nextUrl : String,
			loadMore : true,
			isActive: false
		}),
		methods: {
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					axios.get(this.nextUrl).then(({ data }) => {
						this.items.push(...data.data);
						if(data.links.next != null) {
							this.nextUrl = data.links.next;
						} else {
							this.nextUrl = data.links.next;
						}
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			}
		},
		created() {
			this.nextUrl = this.url;
			this.items = [];
			this.loadNextPage();
		}
	};
</script>

<template>
	<div class="field has-addons">
		<div class="control is-expanded">
			<input class="input is-medium" type="text" v-model="inputSearch" placeholder="تذكر ان اختيارك الآن هو بداية لطريق النجاح">
		</div>
		<div class="control">
			<a class="button is-primary is-medium pr30 pl30" @click.prevent="search">{{t('search')}}</a>
		</div>

	</div>
</template>
<script>
	import trans from "./translation/general.json";
	export default {
		name: 'FindDomain',

		props: {
			sitelocale:{
				type:String,
				required: true
			}
		},
		locales: trans,
		computed: {
			
		},
		mounted() {
			this.$translate.setLang(this.sitelocale);
		},
		data() {
			return {
				inputSearch : ''
			};
		},
		methods: {
			search(){
				if(this.inputSearch!= ''){
					window.location = "https://www.support.murabba.com/cart.php?a=add&domain=register&query="+this.inputSearch 
				}
			}
		},
		created() {
			
		}
	};
</script>

<template>
	<div class="gallery-filter min-height-400" >
		<div class="container">
			<div class="buttons-container has-text-centered" :dir="sitelocale == 'ar' ? 'rtl' :'ltr' ">
				<a href="#" class="button transparent-button has-text-primary has-text-weight-bold ml10 mb30"  @click.prevent="filterall()" :class="[selectedCat=='all' ? 'is-checked' : '']">{{t('all')}}</a>
				<a href="#" class="button transparent-button has-text-primary has-text-weight-bold ml10 mb30" v-for="cat in categories" :class="[cat.slug==selectedCat ? 'is-checked' : '']" @click.prevent="filtercat(cat)">{{ sitelocale == 'ar' ? cat.name_ar : cat.name_en}}</a>
			</div>
			<div  class="columns is-multiline" v-if="display_list.length>0">
				<div class="column is-3 item" v-for="item in display_list">
					<div class="item-container item-active-mobile radius-md">
						<a :href="item.link">
							<div class="item-overlay"></div>
							<lazy-load
							src="/front/img/spinner.gif"
							:lazy-src="item.image ? item.image : '/front/img/placeholder.svg'"
							:lazy-srcset="item.image ? item.image : '/front/img/placeholder.svg'"
							background-color="transparent"
							class="item-image"
							:alt="item.name"
							/>
							<div class="item-details fadeIn-top fadeIn-right">
								<h3 class="has-text-white has-text-weight-bold is-size-5 mb10">{{item.name}}</h3>
								<p class="has-text-white is-size-7">{{item.date}}</p>
							</div>
						</a>
					</div>
				</div>

			</div>
			<div v-else class="empty-page">
				<figure class="image-contain height-300 full-width">
					<lazy-load
					src="/front/img/spinner.gif"
					lazy-src="/front/img/empty.svg"
					lazy-srcset="/front/img/empty.svg"
					background-color="transparent"
					/>
				</figure>
				<p v-if="noData" class="has-text-primary is-size-5 has-text-weight-bold has-text-centered">{{t('no_data')}}</p>
			</div>
		</div>
		
	</div>
</template>

<script>

	import trans from "./translation/gallery.json";
	import SlickAnimation from '../generalComponents/slickCarouselAnimation';
	export default {
		name: 'Gallery',
		components:{
			SlickAnimation
		},
		props: {
			sitelocale: {
				type: String,
				required: true
			},
			categories:{
				required: true
			},
			url:{
				required: true
			}

		},
		data() {
			return {
				options:{
					slidesToShow:5,
					slidesToScroll: 1 ,
					arrows: false,
					dots: false,
					draggable: true,
					focusOnSelect: true ,
					centerMode: true,
					swipeToSlide:true,
					responsive:[
					{
						breakpoint: 1367,
						settings: {
							slidesToShow: 4
						}
					},
					{
						breakpoint: 1201,
						settings: {
							slidesToShow: 3
						}
					},
					{
						breakpoint: 769,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 521,
						settings: {
							slidesToShow: 1
						}
					}
					]
				},
				display_list:null,
				selectedCat: 'all',
				selectedUrl:null,
				noData: false
			}
		},
		mounted() {
			this.$translate.setLang(this.sitelocale);

		},
		created(){
			this.selectedUrl = this.url;
			this.display_list = [];
			this.loadData();
		},
		locales: trans,
		methods: {
			filtercat(cat){
				this.selectedCat = cat.slug;
				this.display_list = [];
				this.selectedUrl = cat.url
				this.loadData();

			},
			filterall(){
				this.selectedCat = 'all';
				this.display_list = [];
				this.selectedUrl = this.url;
				this.loadData();
			// this.display_list = this.list;
			

		},
		reInit() {
			this.$refs.slick_filter.destroy()
			this.$nextTick(() => {
				this.$refs.slick_filter.create()
			})
		},
		loadData(){
			if(this.selectedUrl != null){
				axios.get(this.selectedUrl).then(({ data }) => {
					this.display_list.push(...data.data);
					if(this.display_list.length <= 0){
						this.noData = true
					}else{
						this.noData = false
					}
				});
			}
			
		}
	},
};
</script>

<style lang="scss">

</style>



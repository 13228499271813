<template>
	<div class="">
		<div class="columns is-centered">
			<div class="column is-6 has-text-centered">
				<p class="has-text-primary is-size-5 mb50 mt50">
					<i class="icon icon-problem"></i> 
					<span>
						ماذا تنتظر؟ ارسل تفاصيل طلبك الآن!
					</span>
				</p>
				<a href="#" @click.prevent="handelModal" class="button is-primary is-medium is-radiusless hvr-border-top-bottom mb50">
					<span>احصل على الخدمة</span>
				</a>
			</div>
		</div>
		<div class="modal modal-fx-slideTop apply-modal" :class="[Opened ? 'is-active' :'']">
			<div class="modal-background"></div>
			<div class="modal-content w-80">
				<div class="modal-inner has-boxshadow-modal has-background-white pr0 pl0 radius-md">
					<div class="columns is-centered">
						<div class="column is-5">
							<div class="has-background-primary full-height p40">
								<h3 class="has-text-white is-size-4 has-text-weight-bold mb40 has-text-centered">بيانات التواصل</h3>
								<figure class="main-image image-contain height-260 mb20">                 
									<lazy-load
									src="/front/img/spinner.gif"
									lazy-src="/front/img/illustration/grafic-service2.svg"
									lazy-srcset="/front/img/illustration/grafic-service2.svg"
									background-color="transparent"
									alt="cpanel"
									/>
								</figure>
								<div class="columns is-multiline">
									<div class="column is-6">
										<a href="tel:920033372" class="has-text-white">
											<span class="icon">
												<i class="fas fa-phone"></i>
											</span>
											<span class="is-hidden-mobile">920033372</span>
										</a>
									</div>
									<div class="column is-6">
										<a href="tel:00966115202698" class="has-text-white">
											<span class="icon">
												<i class="fab fa-whatsapp"></i>
											</span>
											<span class="is-hidden-mobile">00966115202698</span>
										</a>
									</div>
									<div class="column is-6">
										<a href="support@murabba.com" class="has-text-white">
											<span class="icon">
												<i class="icon-support"></i>
											</span>
											<span class="is-hidden-mobile">الدعم الفنى</span>
										</a>
									</div>
									<div class="column is-6">
										<a href="https://support.murabba.com/submitticket.php" class="has-text-white">
											<span class="icon">
												<i class="icon-pencil"></i>
											</span>
											<span class="is-hidden-mobile">التذاكر</span>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-7">
							<div class="pt50 pb50 pr20 pl30">
								<h3 class="has-text-primary is-size-4 has-text-weight-bold mb40 has-text-centered">أو أرسل إلينا نموذج طلب وسيتم الرد خلال 24 ساعة.</h3>
								<div class="columns is-centered is-multiline">
									<div class="column is-6">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input name="name" class="input is-medium has-text-centered"
												:class="[errors.has('name') ? 'is-danger' : 'is-info']" type="text"
												:placeholder="t('name')"
												v-model="form.name"
												v-validate="{ required: true , min:3 }"
												required >
												<div v-if="errors.has('name')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('name') }}</div>
												<span v-if="errors.has('name')" class="icon is-small is-left has-text-danger">
													<i class="fas fa-exclamation-circle"></i>
												</span>
											</div>
										</div>
									</div>
									<div class="column is-6">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input name="email" class="input is-medium has-text-centered"
												:class="[errors.has('email') ? 'is-danger' : 'is-info']" type="text"
												:placeholder="t('email')"
												v-model="form.email"
												v-validate="{ required: true , email:true }"
												required >
												<div v-if="errors.has('email')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('email') }}</div>
												<span v-if="errors.has('email')" class="icon is-small is-left has-text-danger">
													<i class="fas fa-exclamation-circle"></i>
												</span>
											</div>
										</div>
									</div>
									<div class="column is-12">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input name="phone" class="input is-medium has-text-centered"
												:class="[errors.has('phone') ? 'is-danger' : 'is-info']" type="text"
												:placeholder="t('phone')"
												v-model="form.phone"
												v-validate="{ required: true , length:11 , numeric:true }"
												required >
												<div v-if="errors.has('phone')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('phone') }}</div>
												<span v-if="errors.has('phone')" class="icon is-small is-left has-text-danger">
													<i class="fas fa-exclamation-circle"></i>
												</span>
											</div>
										</div>
									</div>
									<div class="column is-12">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<textarea name="order" class="textarea is-medium has-text-centered"
												:class="[errors.has('order') ? 'is-danger' : 'is-info']" type="text"
												:placeholder="t('order')"
												v-model="form.order"
												v-validate="{ required: true , length:11 , numeric:true }"
												required >
											</textarea>
											<div v-if="errors.has('order')" class="has-text-danger has-text-centered p5 has-background-alpha-red">{{ errors.first('order') }}</div>
											<span v-if="errors.has('order')" class="icon is-small is-left has-text-danger">
												<i class="fas fa-exclamation-circle"></i>
											</span>
										</div>
									</div>
								</div>
								<div class="mt-20 has-text-centered">
									<button type="submit" class="button is-primary is-medium is-radiusless pr30 pl30" @click.prevent="submit">
										<span>
											{{t('send_cv')}}
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button v-if="Opened" class="modal-close is-large" aria-label="close" @click.prevent="handelModal"></button>
	</div>
</div>
</template>
<script>
import axios from 'axios';
import trans from "./translation/general.json";
import arabic from 'vee-validate/dist/locale/ar';
import { Validator } from 'vee-validate';
export default {
	name: 'GetService',
	props: {
		sitelocale:{
			type:String,
			required: true
		},
		// JobId:{
		// 	type:String,
		// 	required:true
		// },
		action:{
			type:String,
			required:true
		}
	},
	data() {
		return {
			Opened:false,
			form:{
				name:null,
				email:null,
				phone:null,
				order:null,
				// company_job_id:this.JobId ? this.JobId : null
			}
		};
	},
	locales: trans,
	computed: {

	},
	mounted() {
		this.$translate.setLang(this.sitelocale);
	},
	created() {
		this.$validator.localize('ar', {
			messages: arabic.messages,
			attributes: trans.ar,
			custom:trans.ar.custom
		});
		this.$validator.localize('en', {
			custom:trans.en.custom
		});

		Validator.localize(this.sitelocale);
	},
	methods: {
		handelModal(){
			this.Opened= !this.Opened;
			if(this.Opened){
				$('html').addClass('modal-openend')
			}
			else{

				setTimeout(function(){
					$('html').removeClass('modal-openend')
				}, 300);
			}
		},
		submit(){
			this.$validator.validateAll().then((result) => {
				if (result) {
					const action = this.action;
					axios.post(action, this.form)
					.then(response => {
						this.showAlert('success' , response.data.message  , '' , '');
						this.form={
							name:null,
							email:null,
							phone:null,
							order:null,
						}

					})
					.catch(error => {
						this.showAlert('error',
							`${this.$translate.text('يرجي مراجعة البيانات')}`,
							'text',
							`<ul class="AlertList">`+Object.values(error.response.data.errors).map(error => `<li class="mb5 is-size-6">${error[0]}</li>`).join(' ')+`</ul>`
							)
						console.clear();
					})
					.then(()=>{

						setTimeout(()=>{

						}, 3500);
					});

				}

			});
		},
		showAlert(type , title , text , html){
			this.$swal({
				type: type,
				title: title,
				text: text,
				html: html,
				showConfirmButton: false,
				timer:2000,
				customClass: type == 'success' ? 'swal2-is-success' : 'swal2-is-danger',
				backdrop: `
				rgba(36, 140, 204, 0.5)
				`

			});
		}
	},

};
</script>

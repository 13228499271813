/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */



 window.Vue = require('vue');

 /*-------general components------*/
 require('./bulma');
 import'./scripts';
 import VueTranslate from "vue-translate-plugin";
 import LazyLoad from './components/generalComponents/LazyLoad';
 import VueLazyload from 'vue-lazyload';
 import SlickAnimation from './components/generalComponents/slickCarouselAnimation';
 import Slick from 'vue-slick';
 import SocialSharing from 'vue-social-sharing';
 import VueCountdown from '@chenfengyuan/vue-countdown';
 import VeeValidate from 'vee-validate';
 import VueSweetalert2 from 'vue-sweetalert2';
 import Collapse from './components/generalComponents/vue-bulma-collapse/src/Collapse'
 import CollapseItem from './components/generalComponents/vue-bulma-collapse/src/Item'
 import VTooltip from 'v-tooltip'
 import Tabs from './components/generalComponents/tabs/Tabs';
 import Tab from './components/generalComponents/tabs/TabPane';

 /*-------Site components------*/
 import Gallery from './components/siteComponents/Gallery';
 import InnerGallery from './components/siteComponents/InnerGallery';
 import Blog from './components/siteComponents/blog';
 import FindDomain from './components/siteComponents/FindDomain';
 import FlashSale from './components/siteComponents/FlashSale'; 
 import ContactUs from './components/siteComponents/ContactUs';
 import StickyHeader from './components/siteComponents/StickyHeader';
 import Features from './components/siteComponents/Features';
 import SplitHeader from './components/siteComponents/SplitHeader';
 import MenuHeader from './components/siteComponents/MenuHeader';
 import SharePost from './components/siteComponents/SharePost';
 import ApplyJob from './components/siteComponents/ApplyJob';
 import GetService from './components/siteComponents/GetService';
 
 /*-----------components uses--------*/
 Vue.use(VueTranslate);
 Vue.use(SocialSharing);
 Vue.use(VeeValidate);
 Vue.use(VueSweetalert2);
 Vue.component('countdown' , VueCountdown);
 Vue.component('lazy-load' , LazyLoad);
 Vue.use(VTooltip);

 Vue.use(VueLazyload, {
    preLoad: 100,
    error: 'https://via.placeholder.com/1920x600',
    loading: '/front/img/spinner.gif',
    attempt: 3,
    
});

 export {
 	Slick,
 	Gallery,
 	InnerGallery,
 	SlickAnimation,
 	Blog,
 	FindDomain,
 	FlashSale,
 	ContactUs,
 	StickyHeader,
 	Collapse,
 	CollapseItem,
 	Features,
 	SplitHeader,
 	MenuHeader,
 	SharePost,
 	ApplyJob,
 	GetService,
 	Tabs,
 	Tab
 	// ImageSelector
 }
 const app = new Vue({
 	el: '#app',
 	components: {
 		Slick,
 		Gallery,
 		InnerGallery,
 		SlickAnimation,
 		Blog,
 		FindDomain,
 		FlashSale,
 		ContactUs,
 		StickyHeader,
 		Collapse,
 		CollapseItem,
 		Features,
 		SplitHeader,
 		MenuHeader,
 		SharePost,
 		ApplyJob,
 		GetService,
 		Tabs,
 		Tab
 		// ImageSelector

 	}
 }); 

<template>
	<div class="navbar-item menu-header-container">
		<div class="menu-link pt5">
			<a href="#" aria-label="Menu" class="has-text-primary is-block" @click.prevent="handelModal">
				<i class="icon-menu1 is-size-2"></i>
			</a>
		</div>
		<div class="menu-header" :class="[opnened ? 'is-active' : '']">
			<div class="container">
				<div class="top">
					<a href="/" class="has-text-primary is-block" @click.prevent="handelModal">
						<figure class="image-contain height-65 logo">
							<lazy-load
							src="/front/img/spinner.gif"
							lazy-src="/front/img/illustration/logo_inverted.svg"
							lazy-srcset="/front/img/illustration/logo_inverted.svg"
							background-color="transparent"
							alt="Footer Logo"
							/>
						</figure>
					</a>
					<button class="modal-close" aria-label="close" @click.prevent="handelModal"></button>
				</div>
				<div class="bottom vertical-align-parent">
					<div class="vertical-align-child">
						<div class="columns is-mobile">
							<div class="column is-3-desktop is-3-tablet is-3-mobile">
								<ul class="main-list">
									<li v-for="link in links" :style="'transition-delay: '+link.delay+'s;'">
										<a class ="has-text-white is-size-4 has-text-weight-bold" 
										:class="[link == activatedLink ? 'is-checked' : '']"
										:href="link.href" @click.prevent="handelClick(link)">{{link.name}}</a>
									</li>
								</ul>
							</div>
							<div class="column is-9-desktop is-9-tablet is-9-mobile">
								<div class="columns is-multiline" v-if="activatedLink && activatedLink.sub">
									<div v-for="sub in activatedLink.sub" class="column is-4-desktop is-4-tablet is-12-mobile sub-menu">
										<template v-if="sub.sub">
											<h3 class="has-text-white is-size-4 has-text-weight-bold" style="transition-delay: 0.5s">{{sub.name}}</h3>
											<ul class="sub-sub-menu">
												<li v-for="item in sub.sub"  :class="[item.new == true ? 'new-link':'' ]" :style="'transition-delay: '+item.delay+'s;'" >
													<a class="has-text-white" :href="item.href">{{item.name}}</a>
												</li>
											</ul>
										</template>	
										<template v-else>
											<a :href="sub.href">
												<h3 class="has-text-white is-size-4 has-text-weight-bold"  style="transition-delay: 0.5s">{{sub.name}}</h3>
											</a>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'MenuHeader',
		props: {
			links:{
				type:Array
			}
		},
		computed: {

		},
		mounted() {
			
		},
		data() {
			return {
				opnened:false,
				activatedLink:null
			}
		},
		methods: {
			handelModal(){
				this.opnened=!this.opnened;
				if(this.opnened){
					$('html').addClass('modal-openend')
				}
				else{
					
					setTimeout(function(){
						$('html').removeClass('modal-openend')
					}, 500);
				}
			},
			handelClick(link){
				if(link.sub){
					this.activatedLink = link
				}
				else{
					window.location= link.href;
				}
			}
		},

		created() {
			var indent=0.44;
			var sub_indent=0.5;
			this.links.map(
				link => 
				(
					link.active ? this.activatedLink=link : '',
					indent= indent+0.06,
					link.delay = indent,
					link.sub ? 
					link.sub.map(
						sub => 
						(
							sub.sub ? 
							sub.sub.map(
								innerSub =>
								(
									sub_indent= sub_indent+0.06,
									innerSub.delay = sub_indent
								),
								sub_indent = 0.5
							)


							: ''
						)
					)

					: ''
				)
			);
		}
	};
</script>
<style lang="scss" scoped>
	.new-link{
		position: relative;
		&:after{
			content: '';
			width: 35px;
    		height: 35px;
			background-image: url('/front/img/illustration/new_badge.svg');
			position: absolute;
			background-size: contain;
			background-repeat: no-repeat;
			margin-right: 15px;
		}
	}
	@media screen and (max-width: 640px){
		.new-link{
			&:after{
				content: '';
				width: 22px;
				height: 22px;
			}
		}

	}

</style>